.CookieConsentModalContainer {
  @apply fixed top-0 left-0 right-0 z-50 w-full mx-auto h-full w-full
    transition
    duration-150
    ease-in-out;
  @apply grid place-items-center h-screen;
  background-color: rgba(0, 0, 0, 0.68);
}

.CookieConsentModal {
  @apply m-12 p-4 max-w-sm;
  opacity: 1;
  background-color: var(--gray-200);
  border-radius: 8px;
}
