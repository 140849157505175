.greenButton {
  background-color: var(--vytal-green);
  color: var(--layout-high-contast);
}

.greenButton[disabled] {
  opacity: 0.6;
}

.buttonStyle {
  @apply flex justify-center items-center;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  border-radius: 22px;
  padding: 9px 24px;
}

.blackButton {
  background-color: var(--layout-high-contast);
  color: var(--white);
}

.buttonStyle:focus {
  outline: none !important;
}

.outline {
  color: var(--layout-high-contast) !important;
  background: transparent !important;
  border: 2px solid var(--layout-high-contast);
}

.loadingSpinner {
  @apply flex-1 inline-block mr-2;
  width: 16px;
  height: 16px;
}
