@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --vytal-green: #28ad70;
  --dark-gray: #2b363e;
  --white: #ffffff;
  --gray: #8c8c8c;
  --gray-100: #f9f9f9;
  --gray-200: #f3f3f3;
  --gray-600: #939393;
  --gray-700: #646464;
  --gray-900: #282828;
  --gray-blue-dark: #445565;
  --gray-dark: #2b363e;
  --blue-gray: #647989;
  --error-red: #e53e3e;
  --blue-light: #ccd7e0;
  --layout-high-contast: #1c1a20;
  --layout-low-contrast_bg: #ffffff;
  --layout-background: #f5f5f5;
}

@font-face {
  font-family: 'PPEditorialNew-Ultralight';
  font-weight: 200;
  font-display: auto;
  src:
    local('PPEditorialNew-Ultralight'),
    url(../fonts/PPEditorialNew-Ultralight.ttf) format('truetype');
}

@font-face {
  font-family: 'PPFormulaCondensed';
  font-weight: 400;
  font-display: auto;
  src:
    local('PPFormulaCondensed'),
    url(../fonts/PPFormulaCondensed-Black.ttf) format('truetype');
}

#__next {
  height: 100%;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Inter', sans-serif;
  line-height: 1.2;
  font-size: 16px;
  height: 100%;
  overflow: hidden;
}

body {
  height: 100%;
  background: #ffffff;
  background: var(--layout-background);
  background-repeat: no-repeat;
  color: var(--vytal-green);
  background-attachment: fixed;
}

* {
  box-sizing: border-box;
}

a {
  color: var(--vytal-green);
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  text-decoration: underline;
}

/* On Chrome, Safari, Edge, Opera => to remove selector on numeric inputs like 6 digit code */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* On Firefox => to remove selector on numeric inputs like 6 digit code */
input[type='number'] {
  -moz-appearance: textfield;
}

img {
  max-width: 100%;
  display: block;
}

.primary {
  color: var(--vytal-green);
}

.vytal-dark {
  color: var(--layout-high-contast);
}

.vytal-card {
  @apply flex mx-auto rounded-2xl min-h-full;
}

.vytal-leftContainer {
  @apply flex-1 hidden md:flex;
}

.vytal-body {
  @apply flex-1 flex flex-col items-stretch p-5 md:p-4 h-screen rounded-2xl;
}

.vytal-logo-small {
  @apply flex md:hidden;
  width: 139px;
}

.vytal-header {
  @apply flex items-center justify-between;
}

.vytal-content {
  @apply pt-8 mt-2 flex justify-center items-center overflow-y-auto;
}

@media (min-width: 400px) {
  .vytal-content {
    @apply flex-1 pt-2;
  }
}

.vytal-content-inner {
  @apply flex-1;
  max-width: 400px;
  min-height: 500px;
  margin-top: auto;
  margin-bottom: auto;
  color: var(--layout-high-contast);
}

.headline {
  font-size: 36px;
  font-style: normal;
  font-weight: 200;
  line-height: 1.2;
  color: var(--layout-high-contast);
  word-break: break-word;
  font-family: 'PPEditorialNew-Ultralight';
}

.vytal-input {
  background: #ffffff;
  border-radius: 3px;
  color: var(--gray-blue-dark);
  caret-color: var(--vytal-green);
  padding: 15px 16px;
  font-size: 16px;
}

.vytal-input::placeholder {
  color: var(--blue-gray);
}

.error-label {
  @apply text-sm;
  color: var(--error-red);
}

.error-label a {
  color: var(--error-red);
  text-decoration: underline;
}

.move10pixelsUp {
  transform: translateY(-10px);
}

.move20pixelsUp {
  transform: translateY(-20px);
}

.scrollDownButtonWrapper {
  @apply sticky bottom-0 flex justify-center items-end;
  height: 80px;
  background-image: linear-gradient(
    rgba(245, 245, 245, 0),
    rgba(245, 245, 245, 1)
  );
}

.scrollDownIcon {
  @apply flex inline-block justify-center items-center mb-4;
  background-color: var(--vytal-green);
  color: var(--layout-high-contast);
  padding: 8px;
  border-radius: 25px;
  cursor: pointer;
  width: 50px;
  height: 50px;
  box-shadow: 2px 2px 10px 3px rgba(0, 0, 0, 0.3);
}

/* it is probably also possible to change those defaults in tailwind, but I could not figure it out on first try */
a:focus,
area:focus,
input:not([disabled]):focus,
select:not([disabled]):focus,
textarea:not([disabled]):focus,
button:not([disabled]):focus {
  outline: var(--vytal-green) solid 1px;
}

*:focus {
  outline: none;
}
